export default {
  GET(state, data) {
    state.tax = { ...data }
  },
  LIST(state, data) {
    state.taxes = data
  },
  SET_FORM(state, data) {
    state.taxesForm = { ...data }
  },
}
