import crudActions from '@/libs/storeConstActions'
import axios from '@/libs/axios'
import {stringify} from "qs";

const endpoint = 'tax-rules'
const Actions = crudActions(endpoint)

const setDefault = (ctx, data) => axios.post(`${endpoint}/set-default?id=${data.id}`)
// const setDefault = (ctx, data) => axios.get(`${endpoint}`)
const getInActiveList = ({commit}, queryParams) => new Promise((resolve, reject) => {
    queryParams = {...queryParams, ...{is_active: 0}}
    axios.get(`/${endpoint}`, {
        params: queryParams,
        paramsSerializer: params => stringify(params),
    })
        .then(response => {
            commit('LIST', response.data.data.data)
            resolve(response)
        })
        .catch(error => {
            console.log(error)
            reject(error)
        })
})

const getActiveList = ({commit}, queryParams) => new Promise((resolve, reject) => {
    queryParams = {...queryParams, ...{is_active: 1}}
    axios.get(`/${endpoint}`, {
        params: queryParams,
        paramsSerializer: params => stringify(params),
    })
        .then(response => {
            commit('LIST', response.data.data.data)
            resolve(response)
        })
        .catch(error => {
            console.log(error)
            reject(error)
        })
})
const del = (ctx, data) => axios.delete(`/${endpoint}/${data}`)
const update = (ctx, data) => axios.put(`/${endpoint}/${data.id}`, data)
const setActive = (ctx, data) => new Promise((resolve, reject) => {
    data.is_active = 'true';
    axios.put(`/${endpoint}/${data.id}`, data)
        .then(response => {
            resolve(response)
        }).catch(error => {
        console.log(error)
        reject(error)
    })
})
const setInActive = (ctx, data) => new Promise((resolve, reject) => {
    data.is_active = 'false';
    axios.put(`/${endpoint}/${data.id}`, data)
        .then(response => {
            resolve(response)
        }).catch(error => {
        console.log(error)
        reject(error)
    })
})

const duplicate = (ctx, data) => axios.get(`${endpoint}/${data.id}/duplicate`)

export default {
    ...Actions,
    getInActiveList,
    getActiveList,
    setActive,
    setInActive,
    del,
    update,
    setDefault,
    duplicate,
}
